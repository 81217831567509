/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/crypto-js@4.0.0/index.min.js
 * - /npm/crypto-js@4.0.0/core.min.js
 * - /npm/crypto-js@4.0.0/cipher-core.min.js
 * - /npm/crypto-js@4.0.0/crypto-js.min.js
 * - /npm/crypto-js@4.0.0/enc-hex.min.js
 * - /npm/crypto-js@4.0.0/enc-latin1.min.js
 * - /npm/crypto-js@4.0.0/enc-utf8.min.js
 * - /npm/crypto-js@4.0.0/enc-base64.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
